<template>
  <validation-observer ref="simpleRules">
    <section id="2d-to-3d-cards">
      <b-row>
        <b-col md="12" xl="12">
          <b-card title="3D Intra-OralScan (STL or OBJ)" style="height: 57vh">
            <div
              id="stl_cont"
              style="height: calc(100% - 220px)"
              class="w-100 inline-block border"
            >
              <div
                style="
                  position: absolute;
                  margin-top: 4px;
                  margin-left: 8px;
                  color: #ffccff;
                  font-size: smaller;
                "
              >
              {{ $t('Upper Jaw') }}
                
              </div>
              <div
                style="
                  position: absolute;
                  margin-top: 24px;
                  margin-left: 8px;
                  color: #ccffff;
                  font-size: smaller;
                "
              >
              {{ $t('Lower Jaw') }}
                
              </div>
            </div>
            <b-row class="mt-1 pr-1 pl-1">
              <b-col md="4" xl="4">
                <b-form-radio
                  v-model="selectedJaw"
                  name="jaw-selection"
                  value="both"
                  @change="showJaw"
                >
                {{ $t('Show Both Jaws') }}
                  
                </b-form-radio>
              </b-col>

              <b-col md="4" xl="4" class="d-flex justify-content-center">
                <b-form-radio
                  v-model="selectedJaw"
                  name="jaw-selection"
                  value="upper"
                  @change="showJaw"
                >
                {{ $t('Show Upper Jaw') }}
                  
                </b-form-radio>
              </b-col>

              <b-col md="4" xl="4" class="d-flex justify-content-end">
                <b-form-radio
                  v-model="selectedJaw"
                  name="jaw-selection"
                  value="lower"
                  @change="showJaw"
                >
                {{ $t('Show Lower Jaw') }}
                  
                </b-form-radio>
              </b-col>
            </b-row>

            <b-form-file
              accept=".obj, .stl"
              :placeholder="$t('Choose upper jaw or drop it here...')"
              :drop-placeholder="$t('Drop upper jaw here...')"
              class="mt-1"
              size="lg"
              @change="previewUpperJaw"
            />
            <b-form-file
              accept=".obj, .stl"
              :placeholder="$t('Choose lower jaw or drop it here...')"
              :drop-placeholder="$t('Drop lower jaw here...')"
              class="mt-1"
              size="lg"
              @change="previewLowerJaw"
            />
          </b-card>
        </b-col>

        <!-- <b-col md="6" xl="6">
          <b-card
            title="Orthopantomography (JPG, PNG or DICOM)"
            style="height: 57vh"
          >
            <div
              class="w-100 inline-block border"
              style="height: calc(100% - 110px)"
              :style="{
                'background-image': `url(${opgFileUrl})`,
                'background-size': 'contain',
                'background-repeat': 'no-repeat',
                'background-position': 'center',
              }"
            >
              <canvas
                style="
                  width: calc(100% - 50px);
                  position: absolute;
                  top: 50%;
                  transform: translate(0, -50%);
                "
                id="render-canvas"
              />
            </div>

            <b-form-file
              accept=".jpg, .png, .dcm, .dicom"
              placeholder="Choose OPG or drop it here..."
              drop-placeholder="Drop OPG here..."
              class="mt-1"
              size="lg"
              @change="previewOPG"
            />
          </b-card>
        </b-col> -->
      </b-row>
      <b-row>
        <b-col md="12" xl="12">
          <b-card title="" class="d-flex justify-content-end flex-wrap">
            <b-row>
              <b-col md="6" xl="3">
                <b-form-group :label="$t('Name*')" label-for="caseNameInput">
                  <validation-provider
                    #default="{ errors }"
                    name="Name"
                    rules="required"
                  >
                    <b-form-input
                      id="caseNameInput"
                      :state="errors.length > 0 ? false : null"
                      v-model="caseName"

                      placeholder="Case #53A - Whole Recovery"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6" xl="3" class="mb-1">
                <b-form-group
                  :label="$t('Patient Identifier*')"
                  label-for="patientIdInput"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Patient Identifier"
                    rules="required"
                  >
                    <b-form-input
                      id="patientIdInput"
                      :state="errors.length > 0 ? false : null"
                      v-model="casePatientId"
                      placeholder="PA-121E"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4" xl="4">
                <b-form-group :label="$t('Details')" label-for="detailsInput">
                  <b-form-input
                    id="detailsInput"
                    v-model="caseDetails"
                    placeholder="Data provided by Dr. Kubiack on 12-05-22"
                  />
                </b-form-group>
              </b-col>

              <b-col md="2" xl="2">
                <b-button
                  variant="primary"
                  @click="uploadData2DTo3D"
                  block
                  class="mt-2"
                  id="sendButton"
                >
                {{ $t('Send') }}
                  
                </b-button>
                <!--  
              <small :class="`${resolveCasesRemainingUI()}`">{{ casesLeft }} Case(s) remaining</small>
          -->
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormRadio,
  BCard,
  BCardText,
  BCardTitle,
  BImg,
  BFormFile,
  BFormGroup,
  BButton,
} from "bootstrap-vue";
import VueI18n from "vue-i18n";
import { StlViewer } from "@/libs/3dviewer/stl_viewer.min.js";
import {
  DicomImage,
  WindowLevel,
  NativePixelDecoder,
} from "@/libs/dicomviewer/dcmjs-imaging.min.js";
import NProgress from "nprogress";
import FileService from "../services/file.service";
import NotificationService from "../services/notification.service";
import CaseService from "@/services/case.service";
import MetricsService from "@/services/metrics.service";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    BRow,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardTitle,
    BImg,
    BFormFile,
    BFormGroup,
    BButton,
  },

  data() {
    return {
      casesLeft: 0,
      selectedJaw: "both",
      upperJawFile: null,
      lowerJawFileInfo: null,
      opgFile: null,
      upperJawFileInfo: null,
      lowerJawFile: null,
      opgFileInfo: null,
      opgFileUrl: null,
      loadingDone: false,
      caseName: "",
      casePatientId: "",
      caseDetails: "",
    };
  },

  mounted() {
    this.stl_viewer = new StlViewer(document.getElementById("stl_cont"), {
      all_loaded_callback: this.uploadDone.bind(this),
      loading_progress_callback: this.uploadProgress.bind(this),
      center_models: false,
      auto_rotate: true,
      controls: 1,
      allow_drag_and_drop: false,
    });

    /*
    MetricsService.getRemaining2D3DCases().then(function(resp)
    {
      if(typeof resp.data!=="undefined" && typeof resp.data.dataset!=="undefined")
      {
        if(typeof resp.data.dataset[0]!=="undefined")
        {
          this.casesLeft=resp.data.dataset[0]["value"];
        }
        else
        {
          this.casesLeft=0;
        }
      }
      else
      {
        console.log("something went wrong:");
        console.log(resp);
      }
    }.bind(this))
    */
  },

  methods: {
    renderFile: function (file) {
      const reader = new FileReader();
      reader.onload = (file) => {
        var renderingCanvasName = "render-canvas";
        var width = 0;
        var height = 0;
        const arrayBuffer = reader.result;
        const canvasElement = document.getElementById(renderingCanvasName);
        canvasElement.onwheel = undefined;
        canvasElement.onmousedown = undefined;
        canvasElement.onmousemove = undefined;
        canvasElement.onmouseup = undefined;

        const t0 = performance.now();

        let frame = 0;
        let windowing = false;
        let windowLevel = undefined;
        let x = 0;
        let y = 0;

        const image = new DicomImage(arrayBuffer);

        const t1 = performance.now();
        console.log(`Parsing time: ${t1 - t0} ms`);
        console.log(`Width: ${image.getWidth()}`);
        console.log(`Height: ${image.getHeight()}`);
        console.log(`Number of frames: ${image.getNumberOfFrames()}`);
        console.log(`Transfer syntax UID: ${image.getTransferSyntaxUid()}`);

        canvasElement.onwheel = (event) => {
          if (image.getNumberOfFrames() < 2) {
            return;
          }
          event.preventDefault();

          const next = event.deltaY > 0 ? 1 : -1;
          if (
            frame + next > image.getNumberOfFrames() - 1 ||
            frame + next < 0
          ) {
            return;
          }

          const renderingResult = this.renderFrame({
            image,
            frame: frame + next,
            windowLevel,
            canvasElement,
          });
          frame = renderingResult.frame;
        };

        canvasElement.onmousedown = (event) => {
          if (event.button !== 0 || !windowLevel) {
            return;
          }
          x = event.offsetX;
          y = event.offsetY;
          windowing = true;
        };
        canvasElement.onmousemove = (event) => {
          if (event.button !== 0 || !windowLevel) {
            return;
          }
          if (windowing) {
            const diffX = event.offsetX - x;
            const diffY = event.offsetY - y;
            x = event.offsetX;
            y = event.offsetY;

            const ww = windowLevel.getWindow();
            const wl = windowLevel.getLevel();
            if (ww + diffX <= 1) {
              return;
            }

            windowLevel.setWindow(ww + diffX);
            windowLevel.setLevel(wl + diffY);
            const renderingResult = this.renderFrame({
              image,
              frame,
              windowLevel,
              canvasElement,
            });
            windowLevel = renderingResult.windowLevel;
          }
        };
        canvasElement.onmouseup = (event) => {
          if (event.button !== 0 || !windowLevel) {
            return;
          }
          x = 0;
          y = 0;
          windowing = false;
        };

        const renderingResult = this.renderFrame({
          image,
          frame,
          windowLevel,
          canvasElement,
        });
        windowLevel = renderingResult.windowLevel;
      };
      reader.readAsArrayBuffer(file);
    },

    renderFrame: function (opts, width = 0, height = 0) {
      opts.canvasElement.height = opts.canvasElement.width / 2;

      try {
        const t0 = performance.now();
        const renderingResult = opts.image.render({
          frame: opts.frame,
          windowLevel: opts.windowLevel,
        });
        const renderedPixels = new Uint8Array(renderingResult.pixels);
        const t1 = performance.now();

        // generate a second canvas
        var renderer = document.createElement("canvas");
        renderer.width = renderingResult.width;
        renderer.height = renderingResult.height;
        // render our ImageData on this canvas

        var ctx2 = renderer.getContext("2d");

        const imageData = ctx2.createImageData(
          renderingResult.width,
          renderingResult.height
        );
        ctx2.clearRect(
          0,
          0,
          opts.canvasElement.width,
          opts.canvasElement.height
        );
        const canvasPixels = imageData.data;
        for (
          let i = 0;
          i < 4 * renderingResult.width * renderingResult.height;
          i++
        ) {
          canvasPixels[i] = renderedPixels[i];
        }
        ctx2.putImageData(imageData, 0, 0);

        const ctx = opts.canvasElement.getContext("2d");
        ctx.clearRect(
          0,
          0,
          opts.canvasElement.width,
          opts.canvasElement.height
        );
        // Now we can scale our image, by drawing our second canvas
        ctx.drawImage(
          renderer,
          0,
          0,
          opts.canvasElement.width,
          opts.canvasElement.height
        );

        const t2 = performance.now();

        console.log(`Rendering frame: ${opts.frame}`);
        if (renderingResult.windowLevel) {
          console.log(
            `Rendering window: ${renderingResult.windowLevel.toString()}`
          );
        }
        console.log(`Rendering time: ${t1 - t0} ms`);
        console.log(`Drawing time: ${t2 - t1} ms`);

        return renderingResult;
      } catch (err) {
        throw err;
      }
    },

    resolveCasesRemainingUI: function () {
      if (this.casesLeft > 3) {
        return "text-secondary";
      } else if (this.casesLeft > 1) {
        return "text-warning";
      }
      return "text-danger";
    },

    showJaw: function () {
      if (this.selectedJaw == "both") {
        this.stl_viewer.set_opacity(1, 1);
        this.stl_viewer.set_opacity(2, 1);
      } else if (this.selectedJaw == "upper") {
        this.stl_viewer.set_opacity(1, 1);
        this.stl_viewer.set_opacity(2, 0);
      } else {
        this.stl_viewer.set_opacity(1, 0);
        this.stl_viewer.set_opacity(2, 1);
      }
    },

    uploadProgress: function (load_status, load_session) {
      this.loadingDone = false;
      console.log(load_status);
      var sumLoading = 0;
      var allToLoad = 0;
      for (var i = 0; i < load_status.length; i++) {
        if (
          typeof load_status[i] === "undefined" ||
          typeof load_status[i]["total"] === "undefined"
        ) {
          continue;
        }
        allToLoad += load_status[i]["total"];
        sumLoading += load_status[i]["loaded"];
      }

      var percentage = sumLoading / allToLoad;
      console.log(percentage);
      if (percentage > 0.8) {
        NProgress.set(0.6);
        setTimeout(this.keepProgressbarBusy.bind(this), 1100);
      } else {
        NProgress.set(percentage);
      }
    },

    keepProgressbarBusy: function () {
      if (this.loadingDone == false) {
        NProgress.inc(0.1);
        setTimeout(this.keepProgressbarBusy.bind(this), 300);
      } else {
        NProgress.done();
      }
    },

    uploadData2DTo3D: async function () {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (
            this.upperJawFile != null &&
            this.lowerJawFile != null
          ) {
            FileService.uploadFile(
              this.upperJawFile,
              { visibility: 0 },
              0,
              0.33
            ).then(
              function (response) {
                // handle success
                this.upperJawFileInfo = response.data[0];
                console.log(response.data);
                FileService.uploadFile(
                  this.lowerJawFile,
                  { visibility: 0 },
                  0.33,
                  0.33
                ).then(
                  function (response) {
                    this.lowerJawFileInfo = response.data[0];
                    console.log(response.data);
                    var data = {
                      files: {
                        upper: this.upperJawFileInfo?.id,
                        lower: this.lowerJawFileInfo?.id,
                        // opg: this.opgFileInfo?.id,
                      },
                      extensions: [{ id: "3d-reconstruction" }],
                      details: this.caseDetails,
                      name: this.caseName,
                      patientId: this.casePatientId,
                    };

                    this.$store
                      .dispatch("caseLists/create",
                        ...data,
                      )
                      .then(
                        function (response) {
                          NotificationService.showInfo(
                            "Case uploaded for processing!"
                          );
                          //this.casesLeft--;
                        }.bind(this)
                      );
                    // handle success
                    // FileService.uploadFile(
                    //   this.opgFile,
                    //   { visibility: 0 },
                    //   0.66,
                    //   0.33
                    // ).then(
                    //   function (response) {
                    //     this.opgFileInfo = response.data[0];
                    //     console.log(response.data);

                    //     var data = {
                    //       files: {
                    //         upper: this.upperJawFileInfo?.id,
                    //         lower: this.lowerJawFileInfo?.id,
                    //         // opg: this.opgFileInfo?.id,
                    //       },
                    //       extensions: [{ id: "opg-to-3d" }],
                    //       details: this.caseDetails,
                    //       name: this.caseName,
                    //       patientId: this.casePatientId,
                    //     };

                    //     this.$store
                    //       .dispatch("caseLists/create", {
                    //         data,
                    //       })
                    //       .then(
                    //         function (response) {
                    //           NotificationService.showInfo(
                    //             "Case uploaded for processing!"
                    //           );
                    //           //this.casesLeft--;
                    //         }.bind(this)
                    //       );

                    //     // CaseService.createCase(data).then(function (response)
                    //     // {
                    //     //   console.log(response.data);
                    //     //   NotificationService.showInfo("Case uploaded for processing!");
                    //     //   //this.casesLeft--;
                    //     // }.bind(this));
                    //   }.bind(this)
                    // );
                  }.bind(this)
                );
              }.bind(this)
            );
          }
        }
      });
    },

    uploadDone: function () {
      NProgress.done();
      this.loadingDone = true;
    },

    previewUpperJaw: function (file) {
      if (typeof file.dataTransfer !== "undefined") {
        console.log(file);
        this.upperJawFile = file.dataTransfer.files[0];
        this.stl_viewer.remove_model(1);
        this.stl_viewer.add_model({
          id: 1,
          local_file: file.dataTransfer.files[0],
          color: "#FFDDFF",
        });
      } else {
        console.log(file);
        this.upperJawFile = file.target.files[0];
        this.stl_viewer.remove_model(1);
        this.stl_viewer.add_model({
          id: 1,
          local_file: file.target.files[0],
          color: "#FFDDFF",
        });
      }
    },

    previewLowerJaw: function (file) {
      if (typeof file.dataTransfer !== "undefined") {
        console.log(file);
        this.lowerJawFile = file.dataTransfer.files[0];
        this.stl_viewer.remove_model(2);
        this.stl_viewer.add_model({
          id: 2,
          local_file: file.dataTransfer.files[0],
          color: "#DDFFFF",
        });
      } else {
        console.log(file);
        this.lowerJawFile = file.target.files[0];
        this.stl_viewer.remove_model(2);
        this.stl_viewer.add_model({
          id: 2,
          local_file: file.target.files[0],
          color: "#DDFFFF",
        });
      }
    },

    previewOPG: function (file) {
      if (typeof file.dataTransfer !== "undefined") {
        console.log(file);
        let reader = new FileReader();
        reader.onload = (e) => {
          this.opgFileUrl = e.target.result;
        };
        this.opgFile = file.dataTransfer.files[0];
        const name = file.dataTransfer.files[0].name;
        const lastDot = name.lastIndexOf(".");

        const fileName = name.substring(0, lastDot);
        const ext = name.substring(lastDot + 1);

        if (
          ext.toUpperCase() == "DICOM" ||
          ext.toUpperCase() == "DCM" ||
          ext.toUpperCase() == "DICM"
        ) {
          // found dicom
          console.log("dicom");
          document.getElementById("render-canvas");
          this.renderFile(file.dataTransfer.files[0], "render-canvas", 0, 0);
        } else {
          reader.readAsDataURL(file.dataTransfer.files[0]);
        }
      } else {
        console.log(file);
        let reader = new FileReader();
        reader.onload = (e) => {
          this.opgFileUrl = e.target.result;
        };
        this.opgFile = file.target.files[0];

        const name = file.target.files[0].name;
        const lastDot = name.lastIndexOf(".");

        const fileName = name.substring(0, lastDot);
        const ext = name.substring(lastDot + 1);

        if (
          ext.toUpperCase() == "DICOM" ||
          ext.toUpperCase() == "DCM" ||
          ext.toUpperCase() == "DICM"
        ) {
          // found dicom
          console.log("dicom");
          document.getElementById("render-canvas");
          this.renderFile(file.target.files[0], "render-canvas", 0, 0);
        } else {
          reader.readAsDataURL(file.target.files[0]);
        }
      }
    },
  },
};

window.onload = async (event) => {
  await NativePixelDecoder.initializeAsync();
};
</script>

<style></style>
